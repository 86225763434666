import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Über Uns`}</h2>
    <blockquote>
      <p parentName="blockquote">{`Wir bei der K&B Software Solutions entwickeln innerhalb eines einheitlichen Ökosystems Healthcare-Lösungen, welche alle Teilnehmer des Gesundheitsmarktes zukunftsorientiert miteinander verbindet.`}</p>
    </blockquote>
    <p>{`Bei allen unseren Lösungen, Schnittstellen und Programmen stellen wir stets den Patienten und die Maximiereung seiner Gesundheit in den Mittelpunkt.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      