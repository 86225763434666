import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projekte`}</h2>
    <ProjectCard title="Nibyou" link="https://nibyou.de" bg="linear-gradient(to right, #98c1c12e 0%, #87b7b7 100%)" mdxType="ProjectCard">
  Praxissoftware für zertifizierte Ernährungstherapeuten. Von Organisation bis Therapie - alles aus einer Hand.
    </ProjectCard>
    <ProjectCard title="nuclini" link="/nuclini" bg="linear-gradient(to right, #33575f 0%, #579e9c 100%)" mdxType="ProjectCard">
    Open Source Patientenapplikation mit Plug-In-Funktion zur einheitlichen Integration und Nutzung von patientenbezogenen digitalen Versorgungssystemen.
    </ProjectCard>
    <ProjectCard title="oganesson" link="/oganesson" bg="linear-gradient(to right, #7155f7 0%, #aec1ef 100%)" mdxType="ProjectCard">
    Digitale Add-On-Software für medizinisches Fachpersonal zur Erweiterung der Kernsysteme mit einer digitalen Einbindung des Patienten.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      